import React, { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Header, Body, Container } from "../common";
import {
  getIsJSModuleInstanceActionExecuting,
  getModuleInstanceActiveJSActionId,
  getModuleInstanceById,
  getModuleInstancePublicJSCollectionData,
} from "ee/selectors/moduleInstanceSelectors";
import { getModuleById } from "ee/selectors/modulesSelector";
import {
  setModuleInstanceActiveJSAction,
  updateModuleInstance,
  updateModuleInstanceOnPageLoadSettings,
  updateModuleInstanceSettings,
} from "ee/actions/moduleInstanceActions";
import Loader from "../../ModuleEditor/Loader";
import {
  hasExecuteModuleInstancePermission,
  hasManageModuleInstancePermission,
} from "ee/utils/permissionHelpers";
import type { OnUpdateSettingsProps } from "pages/Editor/JSEditor/JSEditorToolbar/types";
import { JSFunctionSettings } from "pages/Editor/JSEditor/JSEditorToolbar/components/JSFunctionSettings";
import { isEmpty, set, sortBy } from "lodash";
import { klona } from "klona";
import JSResponseView from "components/editorComponents/JSResponseView";
import type { AppState } from "ee/reducers";
import equal from "fast-deep-equal/es6";
import { getJSCollectionParseErrors } from "ee/selectors/entitiesSelector";
import { EditorTheme } from "components/editorComponents/CodeEditor/EditorConfig";
import { JSFunctionRun } from "pages/Editor/JSEditor/JSEditorToolbar/components/JSFunctionRun";
import {
  getJSActionOption,
  type JSActionDropdownOption,
} from "pages/Editor/JSEditor/JSEditorToolbar";
import { convertJSActionsToDropdownOptions } from "pages/Editor/JSEditor/JSEditorToolbar/utils";
import { getActionFromJsCollection } from "pages/Editor/JSEditor/utils";
import type { DropdownOnSelect } from "@appsmith/ads-old";
import type { EventLocation } from "ee/utils/analyticsUtilTypes";
import { startExecutingJSFunction } from "actions/jsPaneActions";
import styled from "styled-components";
import { Tab, TabPanel, Tabs, TabsList } from "@appsmith/ads";
import { getPackageById } from "ee/selectors/packageSelectors";
import MissingModule from "../common/MissingModule";
import ParametersView from "./ParametersView";
import { cleanArray } from "./utils";

export interface JSModuleInstanceEditorProps {
  moduleInstanceId: string;
  defaultTab?: ModuleInstanceEditorTab;
}

const StyledJSFunctionRunWrapper = styled.div`
  .ads-v2-select {
    background-color: white;
  }
`;

export enum ModuleInstanceEditorTab {
  SETTINGS = "SETTINGS",
  PARAMETERS = "PARAMETERS",
}

function JSModuleInstanceEditor({
  defaultTab = ModuleInstanceEditorTab.PARAMETERS,
  moduleInstanceId,
}: JSModuleInstanceEditorProps) {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const moduleInstance = useSelector((state) =>
    getModuleInstanceById(state, moduleInstanceId),
  );
  const module = useSelector((state) =>
    getModuleById(state, moduleInstance?.sourceModuleId || ""),
  );
  const pkg = useSelector((state) =>
    getPackageById(state, module?.packageId || ""),
  );
  const publicJSCollectionData = useSelector((state) =>
    getModuleInstancePublicJSCollectionData(state, moduleInstanceId),
  );
  const publicJSCollection = publicJSCollectionData?.config;
  const parseErrors = useSelector(
    (state: AppState) =>
      getJSCollectionParseErrors(state, publicJSCollection?.name || ""),
    equal,
  );
  const activeJSActionId = useSelector((state: AppState) =>
    getModuleInstanceActiveJSActionId(state, publicJSCollection?.id || ""),
  );
  const isExecutingCurrentJSAction = useSelector((state: AppState) =>
    getIsJSModuleInstanceActionExecuting(
      state,
      moduleInstanceId,
      activeJSActionId,
    ),
  );
  const hasMissingModule = Boolean(moduleInstance?.invalids?.length);
  const activeJSAction = publicJSCollection
    ? getActionFromJsCollection(activeJSActionId, publicJSCollection)
    : null;

  const sortedJSactions = sortBy(publicJSCollection?.actions, ["name"]);

  const activJSActionOption = getJSActionOption(
    activeJSAction,
    sortedJSactions,
  ) as JSActionDropdownOption;

  const lastExecutedJSAction = publicJSCollection
    ? getActionFromJsCollection(
        publicJSCollectionData?.lastExecutedActionId || null,
        publicJSCollection,
      )
    : null;

  const isExecutePermitted = hasExecuteModuleInstancePermission(
    moduleInstance?.userPermissions,
  );

  const canManageModuleInstance = hasManageModuleInstancePermission(
    moduleInstance?.userPermissions,
  );

  const parsedDefaultValues = useMemo(() => {
    return Object.entries(moduleInstance?.inputs || {}).reduce(
      (acc, [fnName, value]) => {
        try {
          acc[fnName] = JSON.parse(value);
          const action = publicJSCollection?.actions.find(
            ({ name }) => name === fnName,
          );

          if (action) {
            const jsArgsLength =
              action.actionConfiguration.jsArguments?.length || 0;
            const inputsLength = acc[fnName].length;

            if (jsArgsLength > inputsLength) {
              const elementsToAdd = jsArgsLength - inputsLength;

              acc[fnName].push(...Array(elementsToAdd).fill(""));
            }
          }
        } catch (e) {
          acc[fnName] = [value];
        }

        return acc;
      },
      {} as Record<string, string[]>,
    );
  }, [moduleInstance?.inputs, publicJSCollection?.actions]);

  const onUpdateParameters = useCallback(
    (values: Record<string, Array<string | undefined>>) => {
      const stringifiedInputs = Object.keys(values).reduce(
        (acc, fnName) => {
          acc[fnName] = JSON.stringify(cleanArray(values[fnName]));

          return acc;
        },
        {} as Record<string, string>,
      );

      dispatch(
        updateModuleInstance({
          id: moduleInstanceId,
          moduleInstance: {
            inputs: stringifiedInputs,
          },
        }),
      );
    },
    [dispatch, moduleInstanceId],
  );

  if (
    !moduleInstance ||
    (!hasMissingModule && (!module || !publicJSCollection))
  ) {
    return <Loader />;
  }

  const onUpdateSettings = (props: OnUpdateSettingsProps) => {
    if (!publicJSCollection) return;

    if (
      props.propertyName === "executeOnLoad" &&
      typeof props.value === "boolean"
    ) {
      dispatch(
        updateModuleInstanceOnPageLoadSettings({
          actionId: props.action.id,
          value: props.value,
        }),
      );
    } else {
      const updatedJSCollection = klona(publicJSCollection);
      const updatedAction = klona(props.action);

      set(updatedAction, props.propertyName, props.value);

      updatedJSCollection.actions = updatedJSCollection.actions.map((a) => {
        return a.id === updatedAction.id ? updatedAction : a;
      });

      dispatch(updateModuleInstanceSettings(updatedJSCollection));
    }
  };

  const handleJSActionOptionSelection: DropdownOnSelect = (value) => {
    if (value && publicJSCollection) {
      const jsAction = getActionFromJsCollection(value, publicJSCollection);

      if (jsAction) {
        dispatch(
          setModuleInstanceActiveJSAction({
            jsCollectionId: publicJSCollection.id,
            jsActionId: jsAction.id,
          }),
        );
      }
    }
  };

  const handleRunAction = (
    event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
    from: EventLocation,
  ) => {
    event.preventDefault();

    if (
      publicJSCollection &&
      !disableRunFunctionality &&
      !isExecutingCurrentJSAction &&
      activJSActionOption?.data
    ) {
      const jsAction = activJSActionOption.data;

      if (jsAction.id !== activJSActionOption.data?.id)
        dispatch(
          setModuleInstanceActiveJSAction({
            jsCollectionId: publicJSCollection.id,
            jsActionId: jsAction.id,
          }),
        );

      dispatch(
        startExecutingJSFunction({
          action: jsAction,
          collection: publicJSCollection,
          from: from,
          openDebugger: true,
        }),
      );
    }
  };

  const disableRunFunctionality = Boolean(
    parseErrors.length || isEmpty(sortedJSactions),
  );

  return (
    <Container data-testid="t--module-instance-js-editor">
      <Header
        isDisabled={hasMissingModule || !canManageModuleInstance}
        moduleId={module?.originModuleId}
        moduleInstance={moduleInstance}
        packageId={pkg?.originPackageId}
      >
        {!hasMissingModule && publicJSCollection && (
          <StyledJSFunctionRunWrapper>
            <JSFunctionRun
              disabled={disableRunFunctionality || !isExecutePermitted}
              isLoading={isExecutingCurrentJSAction}
              jsCollection={publicJSCollection}
              onButtonClick={(
                event:
                  | React.MouseEvent<HTMLElement, MouseEvent>
                  | KeyboardEvent,
              ) => {
                handleRunAction(event, "JS_OBJECT_MAIN_RUN_BUTTON");
              }}
              onSelect={handleJSActionOptionSelection}
              options={convertJSActionsToDropdownOptions(sortedJSactions)}
              selected={activJSActionOption}
              showTooltip={!activJSActionOption.data}
            />
          </StyledJSFunctionRunWrapper>
        )}
      </Header>
      <Body>
        {hasMissingModule && <MissingModule moduleInstance={moduleInstance} />}
        {!hasMissingModule && (
          <Tabs
            defaultValue={ModuleInstanceEditorTab.PARAMETERS}
            onValueChange={(tab) => {
              setSelectedTab(tab as ModuleInstanceEditorTab);
            }}
            value={selectedTab}
          >
            <TabsList>
              <Tab
                data-testid={
                  `t--module-instance-js-editor-` +
                  ModuleInstanceEditorTab.PARAMETERS
                }
                value={ModuleInstanceEditorTab.PARAMETERS}
              >
                Parameters
              </Tab>
              <Tab
                data-testid={
                  `t--module-instance-js-editor-` +
                  ModuleInstanceEditorTab.SETTINGS
                }
                value={ModuleInstanceEditorTab.SETTINGS}
              >
                Settings
              </Tab>
            </TabsList>
            <TabPanel value={ModuleInstanceEditorTab.PARAMETERS}>
              <ParametersView
                actions={sortedJSactions}
                defaultValues={parsedDefaultValues}
                moduleInstanceName={moduleInstance.name}
                onUpdate={onUpdateParameters}
                selectedActionName={activJSActionOption.label || ""}
                setSelectedAction={handleJSActionOptionSelection}
              />
            </TabPanel>
            <TabPanel value={ModuleInstanceEditorTab.SETTINGS}>
              <JSFunctionSettings
                actions={sortedJSactions}
                disabled={!canManageModuleInstance}
                onUpdateSettings={onUpdateSettings}
              />
            </TabPanel>
          </Tabs>
        )}
      </Body>
      <JSResponseView
        currentFunction={lastExecutedJSAction}
        debuggerLogsDefaultName={moduleInstance.name}
        disabled={disableRunFunctionality || !isExecutePermitted}
        errors={parseErrors}
        isLoading={isExecutingCurrentJSAction}
        jsCollectionData={publicJSCollectionData}
        onButtonClick={(
          event: React.MouseEvent<HTMLElement, MouseEvent> | KeyboardEvent,
        ) => {
          handleRunAction(event, "JS_OBJECT_RESPONSE_RUN_BUTTON");
        }}
        theme={EditorTheme.LIGHT}
      />
    </Container>
  );
}

export default JSModuleInstanceEditor;
